import {
  SanityHeroCarouselSlide,
  SanityLinkExternal,
  SanityLinkInternal,
} from '@types'
import { useEmblaCarousel } from 'embla-carousel/react'
import { useAnimation } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'
import { Box, Flex } from 'theme-ui'
import useOnScreen from '../../hooks/useOnScreen'
import usePageVisibility from '../../hooks/usePageVisibility'
import { trackHomeSliderClick } from '../../utils/analytics/trackHomeSliderClick'
import BoxMotion from '../BoxMotion'
import Button from '../Button'
import ButtonIcon from '../ButtonIcon'
import Eyebrow from '../Eyebrow'
import LinkSanity from '../LinkSanity'
import NextImage from '../NextImage'
import PortableText from '../PortableText'

export type Props = {
  slides: SanityHeroCarouselSlide[]
}

const SLIDE_INTERVAL = 12000 // ms

// TODO: DRY auto slide functionality with module carousel

const HeroCarousel = (props: Props) => {
  const { slides } = props

  const multipleSlides = slides && slides.length > 1

  const [emblaRef, emblaApi] = useEmblaCarousel({
    draggable: multipleSlides,
    loop: true,
  })
  const controls = useAnimation()
  const visibility = usePageVisibility()

  // Refs
  const refContainer = useRef(null)
  const refTimeout = useRef<ReturnType<typeof window.setTimeout>>()

  // State
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [timerEnabled, setTimerEnabled] = useState(true)

  const { inViewport } = useOnScreen(refContainer)

  // Callbacks
  const handleProgressResume = () => {
    let duration = 0 // seconds

    // Imperatively animate progress bar
    if (controls && timerEnabled) {
      controls.start((custom, current, velocity) => {
        const currentScaleX = Number(current?.scaleX) || 0
        duration = (SLIDE_INTERVAL * (1 - currentScaleX)) / 1000

        return {
          scaleX: 1,
          transition: {
            duration,
            ease: 'linear',
          },
        }
      })
    }

    if (timerEnabled) {
      handleTimerClear()
      if (duration) {
        handleTimerStart(duration * 1000)
      }
    }
  }

  const handleProgressPause = () => {
    if (controls && timerEnabled) {
      handleTimerClear()
      controls.stop()
    }
  }

  const handleNext = () => {
    if (!emblaApi) {
      return
    }

    emblaApi.scrollNext()
  }

  const handlePrevious = () => {
    if (!emblaApi) {
      return
    }

    emblaApi.scrollPrev()
  }

  const handleSelect = (index: number) => {
    if (!emblaApi) {
      return
    }

    emblaApi.scrollTo(index)
  }

  const handleSelected = () => {
    if (!emblaApi) {
      return
    }

    setSelectedIndex(emblaApi.selectedScrollSnap())
    if (controls) {
      controls.set({ scaleX: 0 })
    }
    handleProgressResume()
  }

  const handleTimerStart = (duration: number) => {
    refTimeout.current = setTimeout(() => {
      if (emblaApi) {
        emblaApi.scrollNext()
      }
    }, duration)
  }

  const handleTimerClear = () => {
    if (refTimeout.current) {
      clearTimeout(refTimeout.current)
    }
  }

  // Effects
  // Clear existing timeout on unmount
  useEffect(() => {
    return () => {
      handleTimerClear()
    }
  }, [])

  useEffect(() => {
    if (emblaApi) {
      emblaApi.on('pointerDown', handleProgressPause)
      emblaApi.on('pointerUp', handleProgressResume)
      emblaApi.on('select', handleSelected)
    }

    return () => {
      if (emblaApi) {
        emblaApi.off('pointerDown', handleProgressPause)
        emblaApi.off('pointerUp', handleProgressResume)
        emblaApi.off('select', handleSelected)
      }
    }
  }, [emblaApi, timerEnabled])

  // Manually trigger selected callback on mount (to imperatively start animation and timeouts)
  useEffect(() => {
    if (emblaApi) {
      handleSelected()
    }
  }, [emblaApi])

  // Stop / start progress animation on page visiblity changes
  useEffect(() => {
    if (!timerEnabled) {
      return
    }

    if (!visibility || !inViewport) {
      handleProgressPause()
    } else {
      handleProgressResume()
    }
  }, [inViewport, visibility])

  const handleTrackClick = (link: SanityLinkInternal | SanityLinkExternal) => {
    // Analytics
    const slug = link._type === 'linkInternal' ? link.slug : link.url
    trackHomeSliderClick(slug)
  }

  return (
    <Box className="embla" ref={refContainer}>
      <Box
        className="embla__viewport"
        ref={emblaRef}
        sx={{
          height: '85vh',
          minHeight: ['480px', null, '600px'],
          position: 'relative',
        }}
      >
        <Box className="embla__container" sx={{ height: '100%' }}>
          {slides.map((slide, index) => {
            const link = slide.link || slide.externalLink
            return (
              <Flex
                className="embla__slide"
                key={index}
                sx={{
                  // alignItems: 'flex-start',
                  alignItems: 'center',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                {/* Image */}
                {slide?.image && (
                  <Box
                    sx={{
                      height: '100%',
                      left: 0,
                      position: 'absolute',
                      top: 0,
                      width: '100%',
                    }}
                  >
                    <NextImage image={slide.image} layout="fill" />
                  </Box>
                )}

                {/* Radial gradient */}
                <Box
                  bg="#000000"
                  sx={{
                    height: '100%',
                    left: 0,
                    opacity: 0.2,
                    position: 'absolute',
                    top: 0,
                    width: '100%',
                  }}
                />

                <Box
                  pr={8}
                  sx={{
                    bottom: [17, null, null, 20],
                    left: [4, null, null, 6],
                    position: 'absolute',
                  }}
                >
                  {/* Title */}
                  {slide?.title && (
                    <Box
                      color="white"
                      mb={[3, null, null, 4]}
                      sx={{
                        p: {
                          fontSize: ['xxl', null, null, 'xxxl'],
                          fontWeight: 'semibold',
                          lineHeight: ['default', null, null, 'single'],
                          maxWidth: '700px',
                        },
                      }}
                    >
                      <PortableText blocks={slide.title} />
                    </Box>
                  )}

                  {/* Body */}
                  {slide?.body && (
                    <Box
                      color="white"
                      sx={{
                        fontSize: ['s'],
                        lineHeight: 'body',
                        maxWidth: '420px',
                      }}
                    >
                      {slide.body}
                    </Box>
                  )}

                  {/* Button */}
                  {link && (
                    <Box mt={[4, null, 5]}>
                      <LinkSanity link={link}>
                        <Button
                          background="white"
                          color="midnight"
                          onClick={handleTrackClick.bind(undefined, link)}
                          onMouseEnter={handleProgressPause}
                          onMouseLeave={handleProgressResume}
                        >
                          {slide.link?.title ?? slide.externalLink?.title}
                        </Button>
                      </LinkSanity>
                    </Box>
                  )}
                </Box>
              </Flex>
            )
          })}
        </Box>

        {/* Navigation - sections */}
        {multipleSlides && (
          <Flex
            color="white"
            pt={2}
            sx={{
              bottom: [3, null, null, 6],
              position: 'absolute',
              left: [4, null, null, 6],
              right: [4, null, null, 6],
              zIndex: 2,
            }}
          >
            {slides?.map((slide, index) => (
              <Box
                key={index}
                mr={[3, null, null, 6]}
                onClick={() => handleSelect(index)}
                pb={3}
                sx={{
                  cursor: 'pointer',
                  position: 'relative',
                  width: '100%',
                  '&:last-of-type': {
                    mr: 0,
                  },
                }}
              >
                <Eyebrow>{slide.type}</Eyebrow>

                {/* Progress bar */}
                <Box
                  sx={{
                    bottom: 0,
                    height: '3px',
                    left: 0,
                    position: 'absolute',
                    width: '100%',
                  }}
                >
                  {/* Background */}
                  <Box
                    bg="white"
                    sx={{
                      bottom: 0,
                      height: '100%',
                      left: 0,
                      opacity: 0.25,
                      position: 'absolute',
                      width: '100%',
                    }}
                  />

                  {/* Foreground */}
                  <BoxMotion
                    // framer-motion
                    animate={controls}
                    initial={{
                      scaleX: 0,
                    }}
                    // theme-ui
                    bg="white"
                    sx={{
                      bottom: 0,
                      height: '100%',
                      left: 0,
                      position: 'absolute',
                      transformOrigin: 'top left',
                      width: selectedIndex === index ? '100%' : '0%',
                    }}
                  />
                </Box>
              </Box>
            ))}
          </Flex>
        )}

        {/* Navigation - next / previous buttons */}
        <Box
          sx={{
            bottom: 20,
            display: ['none', null, null, 'flex'],
            position: 'absolute',
            right: 6,
          }}
        >
          <Box mr={1} onClick={handlePrevious}>
            <ButtonIcon
              background="white"
              color="white"
              iconSize="12px"
              size="34px"
              transparent={true}
              type="arrowLeft"
            />
          </Box>

          <Box ml={1} onClick={handleNext}>
            <ButtonIcon
              background="white"
              color="white"
              iconSize="12px"
              size="34px"
              transparent={true}
              type="arrowRight"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default HeroCarousel
