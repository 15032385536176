import { Color, ColorTheme, Module } from '@types'
import React from 'react'
import { Box } from 'theme-ui'
import Anchor from '../Anchor'
import Modules from '../Modules'
import SectionWedge from '../SectionWedge'

type ModuleSection = {
  _type: 'moduleSection'
  background?: Color
  modules: Module[]
  title: string
}

type Props = {
  colorTheme?: ColorTheme
  isHero?: boolean
  isLast?: boolean
  moduleSection: ModuleSection
}

const ModuleSection = (props: Props) => {
  const { colorTheme, isHero, isLast, moduleSection } = props

  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        bg={moduleSection?.background}
        sx={{
          // Prevent collapsing margins
          border: '1px solid transparent',
        }}
      >
        {/* Anchor */}
        {moduleSection?.title && <Anchor title={moduleSection.title} />}

        {/* Modules */}
        {moduleSection?.modules && (
          <Modules
            colorTheme={colorTheme}
            isHero={isHero}
            modules={moduleSection.modules}
          />
        )}
      </Box>

      {/* Wedge */}
      {!isLast && (
        <SectionWedge
          color={moduleSection?.background || 'white'}
          position="top"
        />
      )}
    </Box>
  )
}

export default ModuleSection
