import { Color } from '@types'
import React from 'react'
import { Box } from 'theme-ui'

type Props = {
  color?: Color
  position?: 'bottom' | 'top'
}

const SectionWedge = (props: Props) => {
  const { color, position = 'bottom' } = props

  if (!color) {
    return null
  }

  const path =
    position === 'bottom'
      ? 'M 0 18 L 1366 18 L 0 0 Z'
      : 'M 1366 0 L 0 0 L 1366 18 L 1366 0 Z'

  return (
    <Box
      color={color}
      sx={{
        position: 'absolute',
        [position]: '100%',
        width: '100%',
        zIndex: 1,
      }}
    >
      <svg
        fill="currentColor"
        height="100%"
        preserveAspectRatio="none"
        style={{
          display: 'block',
          transform: 'scale(1)', // Prevent sub-pixel shift in Safari
        }}
        viewBox="0 0 1366 18"
        width="100%"
      >
        <path d={path} />
      </svg>
    </Box>
  )
}

export default SectionWedge
