import React from 'react'
import slug from 'slug'
import { Link } from 'theme-ui'
import { SUBHEADER_HEIGHT_LARGE } from '../../styled/theme'

type Props = {
  title: string
}

const Anchor = (props: Props) => {
  const { title } = props

  const id = title && slug(title, { lower: true })

  return (
    <Link
      id={id}
      sx={{
        display: 'block',
        position: 'relative',
        top: `-${SUBHEADER_HEIGHT_LARGE + 8}px`,
        visibility: 'hidden',
      }}
    />
  )
}

export default Anchor
