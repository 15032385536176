import ModuleDivider from 'components/ModuleDivider'
import ModuleSection from 'components/ModuleSection'

/**
 * Artboards need their shapes masking if there's a wedge,
 * so instead of having the wedge on the bottom of the
 * module extending it, we want it to be lifted up
 * to "chop" into the hero module, it therefore needs
 * to know the first module's background-color
 */
export const getWedgeColor = (
  sections: (ModuleDivider | ModuleSection)[] | undefined
) =>
  (sections?.find(item => item._type === 'moduleSection') as ModuleSection)
    ?.background
