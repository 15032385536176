import { ColorTheme, ModuleDivider } from '@types'
import SectionWedge from 'components/SectionWedge'
import React from 'react'
import { Box } from 'theme-ui'
import { getWedgeColor } from 'utils/getWedgeColor'
import DividerHorizontalRandom from '../DividerHorizontalRandom'
import ModuleSection from '../ModuleSection'

type Props = {
  colorTheme?: ColorTheme
  isHero?: boolean
  moduleSections: (ModuleDivider | ModuleSection)[]
  hideWedge?: boolean
}

const ModuleSections = (props: Props) => {
  const { colorTheme, isHero, moduleSections, hideWedge } = props

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      {!hideWedge && <SectionWedge color={getWedgeColor(moduleSections)} />}
      {moduleSections?.map((moduleSection, index) => {
        if (moduleSection._type === 'moduleDivider') {
          return (
            <DividerHorizontalRandom
              key={index}
              mx="auto"
              my={5}
              px={6}
              sx={{
                maxWidth: 'moduleTextTwoColMaxWidth',
                opacity: 0.2,
                width: '100%',
              }}
            />
          )
        }
        if (moduleSection._type === 'moduleSection') {
          return (
            <ModuleSection
              colorTheme={colorTheme}
              isHero={isHero}
              isLast={index === moduleSections.length - 1}
              key={index}
              moduleSection={moduleSection}
            />
          )
        }
        return null
      })}
    </Box>
  )
}

export default ModuleSections
